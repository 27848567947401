<template>
    <div>
        <v-layout row wrap justify-end>
            <v-menu
                :disabled="disabled"
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedStartDateFormatted"
                    :label="plabel"
                    :hint="selectType == 'month' ? 'MM-YYYY' : 'DD-MM-YYYY'"
                    persistent-hint
                    :prepend-icon="prependIcon != null ? prependIcon : 'fa-calendar-alt'"
                    :disabled="disabled"
                    readonly
                    v-on="on"
                    :rules="rules"
                    ></v-text-field>
                </template>
                <v-date-picker :type="selectType ? selectType : 'date'" v-bind:value="value ? value : tempDate" no-title v-on:change="onChangeDate"></v-date-picker>
            </v-menu>
        </v-layout>
    </div>
</template>

<script>
import moment from 'moment'
import configs from "@/configs"

export default {
    props: {
        value: String,
        plabel: String,
        rules: Array,
        disabled: Boolean,
        selectType: String,
        prependIcon: String,
    },
    data() {
        return {
            menuStart: false,
            tempDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
        };
    },
    computed: {
        computedStartDateFormatted () {
            let format = this.selectType == 'month' ? 'MM-YYYY' : configs.SHORT_DATE_FORMAT;
            return this.value ? moment(this.value).format(format) : '';
        }
    },
    methods: {
        onChangeDate(val) {
            this.$emit('onChangeDate', val);
            this.menuStart = false;
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
    }
}
</script>